export const CarTrendingData = [
    {
        type: '1',
        photo: 'http://localhost:3000/yaris.jpg',
        rate: '50 Reviews',
        HotelName: 'Toyota Yaris',
        location: '2017',
        Kilo: '90 km away',
        time: 'Day',
        qty: '50',
        discount: '199',
    },
    {
        type: '1',
        photo: 'http://localhost:3000/v8.jpg',
        rate: '50 Reviews',
        HotelName: 'Toyota Landcruiser v8',
        location: '2018',
        Kilo: '90 km away',
        time: 'Day',
        qty: '180',
        discount: '199',
    },
    {
        type: '1',
        photo: 'http://localhost:3000/coaster.jpg',
        rate: '50 Reviews',
        HotelName: 'Toyota Coaster 29 Seats',
        location: '2018',
        Kilo: '90 km away',
        time: 'Day',
        qty: '270',
        discount: '199',
    },
    {
        photo: 'http://localhost:3000/yaris.jpg',
        rate: '50 Reviews',
        HotelName: 'Toyota Yaris',
        location: '2017',
        Kilo: '90 km away',
        time: 'Day',
        qty: '50',
        discount: '199',
    },
    {
        photo: 'http://localhost:3000/v8.jpg',
        rate: '50 Reviews',
        HotelName: 'Toyota Landcruiser v8',
        location: '2018',
        Kilo: '90 km away',
        time: 'Day',
        qty: '180',
        discount: '199',
    },
    {
        photo: 'http://localhost:3000/coaster.jpg',
        rate: '50 Reviews',
        HotelName: 'Toyota Coaster 29 Seats',
        location: '2018',
        Kilo: '90 km away',
        time: 'Day',
        qty: '270',
        discount: '199',
    }
]