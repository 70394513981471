export const StaysnearYouData = [
    {
        photo: 'http://localhost:3000/ubumwe.jpg',
        rate: 'Very good 8.2',
        HotelName: 'Ubumwe Grand Hotel',
        location: '',
        Kilo: '139',
        time: 'Available From 20 June',
        qty: '80',
        discount: '199',
    },
    {
        photo: 'http://localhost:3000/mariot.jpg',
        rate: 'Very good 8.2',
        HotelName: 'Kigali Marriot Hotel',
        location: '',
        Kilo: '149',
        time: 'Available From 20 June',
        qty: '80',
        discount: '199',
    },
    {
        photo: 'http://localhost:3000/saint.jpg',
        rate: 'Good 7.1',
        HotelName: 'Saint Fmille Hotel',
        location: '',
        Kilo: '92',
        time: 'Available From 20 June',
        qty: '80',
        discount: '199',
    },
    {
        photo: 'http://localhost:3000/ubumwe.jpg',
        rate: 'Good 7.5',
        HotelName: 'M Hotel',
        location: 'Downtown',
        Kilo: '284',
        time: 'Available From 20 June',
        qty: '80',
        discount: '199',
    }
]