export const UpComingEventData = [
    {
        type: '1',
        photo: 'http://localhost:3000/yaris.jpg',
        rate: '50 Reviews',
        HotelName: 'JAN 20TH, 2019',
        location: 'Conference in Rwanda',
        Kilo: '90 km away',
        time: 'Day',
        qty: '50',
        discount: '199',
    },
    {
        type: '1',
        photo: 'http://localhost:3000/v8.jpg',
        rate: '50 Reviews',
        HotelName: 'JAN 20TH, 2019',
        location: 'Conference in Rwanda',
        Kilo: '90 km away',
        time: 'Day',
        qty: '180',
        discount: '199',
    },
    {
        type: '1',
        photo: 'http://localhost:3000/coaster.jpg',
        rate: '50 Reviews',
        HotelName: 'JAN 20TH, 2019',
        location: 'Conference in Rwanda',
        Kilo: '90 km away',
        time: 'Day',
        qty: '270',
        discount: '199',
    },
    {
        type: '2',
        photo: 'http://localhost:3000/yaris.jpg',
        rate: '50 Reviews',
        HotelName: 'JAN 20TH, 2019',
        location: 'Conference in Rwanda',
        Kilo: '90 km away',
        time: 'Day',
        qty: '50',
        discount: '199',
    },
    {
        type: '2',
        photo: 'http://localhost:3000/v8.jpg',
        rate: '50 Reviews',
        HotelName: 'JAN 20TH, 2019',
        location: 'Conference in Rwanda',
        Kilo: '90 km away',
        time: 'Day',
        qty: '180',
        discount: '199',
    },
    {
        type: '2',
        photo: 'http://localhost:3000/coaster.jpg',
        rate: '50 Reviews',
        HotelName: 'JAN 20TH, 2019',
        location: 'Conference in Rwanda',
        Kilo: '90 km away',
        time: 'Day',
        qty: '270',
        discount: '199',
    }
]