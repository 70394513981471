export const BestSellerData = [
    {
        photo: 'http://localhost:3000/ubumwe.jpg',
        rate: '4.4',
        HotelName: 'Ubumwe Grand Hotel',
        location: 'Kigali,Rwanda',
        Kilo: '90 km away',
        time: 'Available From 20 June',
        qty: '80',
        discount: '199',
    },
    {
        photo: 'http://localhost:3000/mariot.jpg',
        rate: '4.4',
        HotelName: 'Kigali Marriot Hotel',
        location: 'Kigali,Rwanda',
        Kilo: '90 km away',
        time: 'Available From 20 June',
        qty: '80',
        discount: '199',
    },
    {
        photo: 'http://localhost:3000/saint.jpg',
        rate: '4.4',
        HotelName: 'Saint Fmille Hotel',
        location: 'Kigali,Rwanda',
        Kilo: '90 km away',
        time: 'Available From 20 June',
        qty: '80',
        discount: '199',
    },
    {
        photo: 'http://localhost:3000/mhotel.jpg',
        rate: '4.4',
        HotelName: 'M Hotel',
        location: 'Kigali,Rwanda',
        Kilo: '90 km away',
        time: 'Available From 20 June',
        qty: '80',
        discount: '199',
    },
    {
        photo: 'http://localhost:3000/dove.jpg',
        rate: '4.4',
        HotelName: 'Dove Hotel Kigali',
        location: 'Kigali,Rwanda',
        Kilo: '90 km away',
        time: 'Available From 20 June',
        qty: '80',
        discount: '199',
    },
    {
        photo: 'http://localhost:3000/boa.jpg',
        rate: '4.4',
        HotelName: 'Baobab Hotel Kigal',
        location: 'Kigali,Rwanda',
        Kilo: '90 km away',
        time: 'Available From 20 June',
        qty: '80',
        discount: '199',
    },
]